.contactBanner{
    background: rgb(19,184,165);
    background: linear-gradient(90deg, rgba(19,184,165,1) 0%, rgba(23,185,162,1) 20%, rgba(69,192,129,1) 100%);
    position: relative;
    display: flex;
    justify-content: flex-end;
    .titleBlock{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        h1{
            color: $color-white;
            margin: 0;
        }
    }

}

.contact-us{
    background-color: #f7f8fc;
    padding: 3rem 0 20rem;

    .contactBlock{
        background-color: $color-white;
        border-radius: 1.5rem;
        padding: 8rem 1.5rem;

        .titleBlock{
            max-width: 80rem;
            margin: 0 auto 6rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            h2{
                text-align: center;
            }
            p{
                text-align: center;
                margin-bottom: 0;
                line-height: 1.5;
                font-size: $font-size-large;
                @include font-md();
            }
        }

        .contact-form{
            max-width: 50rem;
            margin: 0 auto;

            .formControl{
                margin-bottom: 4rem;
                .formField{
                    font-size: $font-size-big;
                }
            }
        }
    }
}