// Buttons
.btnBlock{
	display: flex;
    a{text-transform: uppercase; font-size: 1.3rem;}
	.btn{
		&:not(:last-child){
			margin-right: 1.5rem;
		}
	}
}

.btn {
	font-size: $font-size-tiny;
	display: flex;
	border: none;
	outline: none;
	line-height: 1;
	box-shadow: none;
	border-radius: 0;
	color: $color-white;
	text-decoration: none;
	padding: 1rem 1.5rem;
	background: rgb(19,184,165);
	background: linear-gradient(135deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);
	font-weight: $font-weight-medium;
	transition: all 0.4s ease-out;
	border-radius: .4rem;
	justify-content: center;
	font-size: $font-size-large;
	@include font-semiBold();
	&:hover{
		color: $color-white;
		background: rgb(19,184,165);
		background: linear-gradient(-45deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);
	}

	&:focus, &:visited, &:active{
		outline: 0;
		box-shadow: none;
	}


	&.btn-lg{
		padding: 1.2rem 4rem;
	}
	&.btn-md{
		padding: 1.4rem 2rem;
		min-width: 15rem;
		font-size: 2.4rem;
	}
	&.btn-full{
		width: 100%;
	}

	&.btn-secondary{
		background: transparent;
		border:solid 0.2rem $color-secondary;
		color: $color-dark3;
		&:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active{
			outline: 0;
			box-shadow: none;
			border:solid 0.2rem $color-primary;
			color: $color-white;
			background: rgb(19,184,165);
			background: linear-gradient(-45deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);
		}
		&:hover, &.active{
			color: $color-white;
			background: rgb(19,184,165);
			background: linear-gradient(-45deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);
		}
	}
	
	&.btn-border-white{
		background: transparent;
		border:solid 0.2rem $color-white;
		box-shadow: 0px 10px 32px 0px rgba(0, 0, 0, 0.14);
		color: $color-white;
		border-radius: 0.6rem;
		&:hover{
			color: $color-white;
			background: rgb(19,184,165);
			background: linear-gradient(-45deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);
		}
	}

	&.btnLoader{
		position: relative;
		&::after, &::before{
			content: "";
			position: absolute;
		}
		&::before{
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 8rem;
			height: 2rem;
			background-repeat: no-repeat;
			background-size: contain;
			background-image: image('svg/three-dots.svg');
			z-index: 2;
		}
		&::after{
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: .4rem;
			background: rgb(19,184,165);
			background: linear-gradient(-45deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);
			z-index: 1;
		}
	}
}

