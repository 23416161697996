.history{
    &__tabs{
        margin-bottom: 5rem;
        display: flex;
        align-items: center;
        
        &--list{
            font-size: $font-size-big;
            padding: 1rem 4rem;
            border:solid 0.1rem $color-primary;
            @include font-semiBold();
            text-align: center;
            cursor: pointer;

            &.active{
                color:$color-white;
                background: rgb(19,184,165);
			    background: linear-gradient(-45deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);
            }

            &:first-child{
                border-radius: 0.8rem 0 0 0.8rem;
                border-right: 0;
            }
            &:last-child{
                border-radius: 0 0.8rem 0.8rem 0;
            }
        }
    }

    .historyDetail{
        display: none;
        flex-wrap: wrap;
        justify-content: space-between;
        &.showTab{
            display: flex;
        }
    }
    &__list{
        width: 48%;
        background-color: $color-white;
        border-radius: 1rem;
        padding: 2rem 2rem;
        margin-bottom: 3rem;

        .titleBlock{
            h3{
                @include font-regular();
            }
        }
        .historyOrder{
            margin-bottom: 1.5rem;
            display: flex;
            justify-content: space-between;
            .order{
                @include font-exBold();
                font-size: 2.4rem;
            }
            .price{
                @include font-exBold();
                font-size: 2.4rem;
            }
        }
        .order-id{
            margin-bottom: 2rem;
            p{
                font-size: $font-size-big;
                @include font-regular();
                margin: 0;
            }
        }

        .order-status{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .status{
                color: $color-white;
                padding: 0.5rem 3rem 0.5rem 1.5rem ;
                background: rgb(19,184,165);
                background: linear-gradient(90deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 1.6rem;
                    border-color: transparent $color-white transparent transparent ;
                }
                &::before{
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 100%;
                    width: 2rem;
                    background-color: rgba(19,184,165,1);
                }
            }
        }
    }
}