.dashboard{
    .dash_title{
        margin-bottom: 2rem;
        
        @media(min-width: $tablet-big){
            display: flex;
            justify-content: space-between;
        }

        .sortBlock{
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__label{
                font-size: $font-size-large;
                margin-right: 1.5rem;
            }

            &__field{
                background: $color-white;
                padding: 1rem 2rem;
                border:solid 0.1rem #dbdbdb;
                border-radius: 0.5rem;
                width: 15rem;
                font-size: $font-size-large;
                @media(min-width: $tablet-big){
                    width: 23rem;
                }
            }
        }
    }
    h3{
        margin: 0;
    }
}

.latest-offer{
    padding: 2rem 0 3rem;
    
    .offerList{
        padding: 1.5rem;
        background-color: $color-white;
        border-radius: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &__item{
            width: 32%;
            margin-bottom: 3rem;
            a{
                display: block;

            }
        }
    }
}