@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800&display=swap');

@mixin font-light {
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
}
@mixin font-regular {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
}
@mixin font-md {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
}
@mixin font-semiBold {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
}
@mixin font-bold {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
}
@mixin font-exBold {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
}