@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@400;500&family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap');

@import 'abstracts/variables';
@import 'abstracts/mixin';
@import 'abstracts/functions';

@import 'base/animations';
@import 'base/base';
@import 'base/fonts';
@import 'base/helper';
@import 'base/utilities';
@import 'base/typography';

@import 'components/button';
@import 'components/section';
@import 'components/headings';
@import 'components/forms';
@import 'components/modal';

@import 'layouts/header';
@import 'layouts/menu';
@import 'layouts/grid';
@import 'layouts/footer';

@import 'vendors/slick';

@import 'pages/landing';
@import 'pages/category-detail';
@import 'pages/services';
@import 'pages/my-account';
@import 'pages/contact-us';
@import 'pages/about-us';
@import 'pages/latest-offer';
@import 'pages/my-order';
@import 'pages/my-profile';
@import 'pages/bidder-profile';
@import 'pages/my-request';
@import 'pages/my-subscription';
@import 'pages/delivery-status';
@import 'pages/seller-home';
@import 'pages/notification';
@import 'pages/service-history';
@import 'pages/404';
@import 'pages/seller-dashboard';

