.latestBid{
    padding: 6rem 0;

    .titleBlock{
        h2{
            margin-bottom: 2rem;
        }
    }

    .latestBid__slider{
        .slide{
            padding: 3rem 3rem 3rem 0;
        }
        .slick-list{
            padding: 0 !important;
        }
        .slick-arrow{
            transform: translate(0, -50%);
            position:absolute;
            bottom:100%;
            text-indent: -200px;
            overflow: hidden;
            border: 0;
            outline: none;
            background-color: $color-white;
            box-shadow: 0px 10px 24px 0px rgba(10, 26, 147, 0.09);  
            width: 8.5rem;
            height: 4rem;
            border-radius: 3rem;
            border:solid 0.2rem $color-white;
            background-image: image('icons.png');
            background-repeat: no-repeat;
            z-index: 5;
            &::before{
                content: "";
                position: absolute;
                top: -0.2rem;
                right: -0.2rem;
                bottom: -0.2rem;
                left: -0.2rem;
                border-radius: 3rem;
                background: rgb(19,184,165);
                background: linear-gradient(0deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);
                z-index: -1;
                display: none;
            }
            &:hover{
                border:solid 0.2rem $color-secondary;
            }
            
            &.slick-next{
                right: 2rem;
                background-position: -24.8rem 0.4rem;
            }
            &.slick-prev{
                right: 12rem;
                background-position: -14.8rem 0.4rem;
            }
        }
        .card{
            box-shadow: 0px 10px 70px 0px rgba(0, 0, 0, 0.09);
            border-radius: 1.5rem;
            background-color: $color-white;
            overflow: hidden;
            display: flex;
            border:0;
            width: 33rem;
            padding: 3rem 1.5rem 2rem;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h3{
                @include font-md();
                text-align: center;
            }
            p{
                text-align: center;
                font-size: 1.7rem;
            }

            .bidInfo{
                width: 100%;
                display: flex;
                justify-content: space-between;
                &:not(:last-child){
                    margin-bottom: 2.5rem;
                }
                &__list{
                    &:not(:last-child){
                        margin-right: 1rem;
                    }
                    .label{
                        margin-bottom: 0;
                        font-size: 1.7rem;
                    }
                    .value{
                        font-size: 2.6rem;
                        @include font-semiBold();
                    }
                }
            }
        }
    }
}

.serviceBid{
    padding: 5rem 0;

    &__block{
        padding: 10rem 0;
        background: image("seller-home/service-bid.jpg") no-repeat top center;

        .titleBlock{
            max-width: 110rem;
            margin: 0 auto 3rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h1{
                color: $color-white;
                font-size: 9rem;
            }
            p{
                text-align: center;
                color: $color-white;
                font-size: $font-size-big;
            }

        }

        .btnBlock{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.bid{
    padding: 5rem 0;
    
    &__tabs{
        margin-bottom: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        
        &--list{
            font-size: 3.2rem;
            padding: 1.5rem 4rem;
            border:solid 0.2rem $color-primary;
            @include font-semiBold();
            min-width: 30rem;
            text-align: center;
            cursor: pointer;

            &.active{
                color:$color-white;
                background: rgb(19,184,165);
			    background: linear-gradient(-45deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);
            }

            &:first-child{
                border-radius: 1.5rem 0 0 1.5rem;
                border-right: 0;
            }
            &:last-child{
                border-radius: 0 1.5rem 1.5rem 0;
            }
        }
    }

    &__content{
        display: none;
        justify-content: space-between;
        flex-wrap: wrap;
        &.showTab{
            display: flex;
        }
        &--list{
            width: 23%;
            margin-bottom: 3rem;
            .card{
                box-shadow: 0px 10px 70px 0px rgba(0, 0, 0, 0.09);
                border-radius: 1.5rem;
                background-color: $color-white;
                overflow: hidden;
                display: flex;
                border:0;
                width: 100%;
                padding: 3rem 1.5rem 2rem;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                figure{
                    margin-bottom: 1.5rem;
                    height: 9.1rem;
                }
                h3{
                    @include font-md();
                    text-align: center;
                }
                p{
                    text-align: center;
                    font-size: 1.7rem;
                }
    
                .bidInfo{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    &:not(:last-child){
                        margin-bottom: 2.5rem;
                    }
                    &__list{
                        &:not(:last-child){
                            margin-right: 1rem;
                        }
                        .label{
                            margin-bottom: 0;
                            font-size: 1.7rem;
                        }
                        .value{
                            font-size: 2.6rem;
                            @include font-semiBold();
                        }
                    }
                }
            }
        }
    }
}