// Margins
.margin-top-0  {margin-top:0    !important}
.margin-top-5  {margin-top:.5rem  !important}
.margin-top-10 {margin-top:1rem !important}
.margin-top-15 {margin-top:1.5rem !important}
.margin-top-20 {margin-top:2rem !important}
.margin-top-25 {margin-top:2.5rem !important}
.margin-top-30 {margin-top:3rem !important}
.margin-top-35 {margin-top:3.5rem !important}
.margin-top-40 {margin-top:4rem !important}
.margin-top-45 {margin-top:4.5rem !important}
.margin-top-50 {margin-top:5rem !important}
.margin-top-55 {margin-top:5.5rem !important}
.margin-top-60 {margin-top:6rem !important}
.margin-top-65 {margin-top:6.5rem !important}
.margin-top-70 {margin-top:7rem !important}
.margin-top-75 {margin-top:7.5rem !important}
.margin-top-80 {margin-top:8rem !important}

.margin-left-0 {margin-left:0 !important}
.margin-left-5 {margin-left:.5rem !important}
.margin-left-10 {margin-left:1rem !important}
.margin-left-15 {margin-left:1.5rem !important}
.margin-left-20 {margin-left:2rem !important}
.margin-left-25 {margin-left:2.5rem !important}
.margin-left-30 {margin-left:3rem !important}
.margin-left-35 {margin-left:3.5rem !important}
.margin-left-40 {margin-left:4rem !important}
.margin-left-45 {margin-left:4.5rem !important}
.margin-left-50 {margin-left:5rem !important}
.margin-left-55 {margin-left:5.5rem !important}
.margin-left-60 {margin-left:6rem !important}
.margin-left-65 {margin-left:6.5rem !important}
.margin-left-70 {margin-left:7rem !important}
.margin-left-75 {margin-left:7.5rem !important}
.margin-left-80 {margin-left:8rem !important}

.margin-bottom-0 {margin-bottom:0 !important}
.margin-bottom-5 {margin-bottom:.5rem !important}
.margin-bottom-10 {margin-bottom:1rem !important}
.margin-bottom-15 {margin-bottom:1.5rem !important}
.margin-bottom-20 {margin-bottom:2rem !important}
.margin-bottom-25 {margin-bottom:2.5rem !important}
.margin-bottom-30 {margin-bottom:3rem !important}
.margin-bottom-35 {margin-bottom:3.5rem !important}
.margin-bottom-40 {margin-bottom:4rem !important}
.margin-bottom-45 {margin-bottom:4.5rem !important}
.margin-bottom-50 {margin-bottom:5rem !important}
.margin-bottom-55 {margin-bottom:5.5rem !important}
.margin-bottom-60 {margin-bottom:6rem !important}
.margin-bottom-65 {margin-bottom:6.5rem !important}
.margin-bottom-70 {margin-bottom:7rem !important}
.margin-bottom-75 {margin-bottom:7.5rem !important}
.margin-bottom-80 {margin-bottom:8rem !important}

.margin-right-0 {margin-right: 0 !important}
.margin-right-5 {margin-right:.5rem !important}
.margin-right-10 {margin-right:1rem !important}
.margin-right-15 {margin-right:1.5rem !important}
.margin-right-20 {margin-right:2rem !important}
.margin-right-25 {margin-right:2.5rem !important}
.margin-right-30 {margin-right:3rem !important}
.margin-right-35 {margin-right:3.5rem !important}
.margin-right-40 {margin-right:4rem !important}
.margin-right-45 {margin-right:4.5rem !important}
.margin-right-50 {margin-right:5rem !important}
.margin-right-55 {margin-right:5.5rem !important}
.margin-right-60 {margin-right:6rem !important}
.margin-right-65 {margin-right:6.5rem !important}
.margin-right-70 {margin-right:7rem !important}
.margin-right-75 {margin-right:7.5rem !important}
.margin-right-80 {margin-right:8rem !important}



// Padding
.padding-top-0 {padding-top:0 !important}
.padding-top-5 {padding-top:.5rem !important}
.padding-top-10 {padding-top:1rem !important}
.padding-top-15 {padding-top:1.5rem !important}
.padding-top-20 {padding-top:2rem !important}
.padding-top-25 {padding-top:2.5rem !important}
.padding-top-30 {padding-top:3rem !important}
.padding-top-35 {padding-top:3.5rem !important}
.padding-top-40 {padding-top:4rem !important}
.padding-top-45 {padding-top:4.5rem !important}
.padding-top-50 {padding-top:5rem !important}
.padding-top-55 {padding-top:5.5rem !important}
.padding-top-60 {padding-top:6rem !important}
.padding-top-65 {padding-top:6.5rem !important}
.padding-top-70 {padding-top:7rem !important}
.padding-top-75 {padding-top:7.5rem !important}
.padding-top-80 {padding-top:8rem !important}

.padding-left-0 {padding-left:0 !important}
.padding-left-5 {padding-left:.5rem !important}
.padding-left-10 {padding-left:1rem !important}
.padding-left-15 {padding-left:1.5rem !important}
.padding-left-20 {padding-left:2rem !important}
.padding-left-25 {padding-left:2.5rem !important}
.padding-left-30 {padding-left:3rem !important}
.padding-left-35 {padding-left:3.5rem !important}
.padding-left-40 {padding-left:4rem !important}
.padding-left-45 {padding-left:4.5rem !important}
.padding-left-50 {padding-left:5rem !important}
.padding-left-55 {padding-left:5.5rem !important}
.padding-left-60 {padding-left:6rem !important}
.padding-left-65 {padding-left:6.5rem !important}
.padding-left-70 {padding-left:7rem !important}
.padding-left-75 {padding-left:7.5rem !important}
.padding-left-80 {padding-left:8rem !important}

.padding-bottom-0 {padding-bottom:0 !important}
.padding-bottom-5 {padding-bottom:.5rem !important}
.padding-bottom-10 {padding-bottom:1rem !important}
.padding-bottom-15 {padding-bottom:1.5rem !important}
.padding-bottom-20 {padding-bottom:2rem !important}
.padding-bottom-25 {padding-bottom:2.5rem !important}
.padding-bottom-30 {padding-bottom:3rem !important}
.padding-bottom-35 {padding-bottom:3.5rem !important}
.padding-bottom-40 {padding-bottom:4rem !important}
.padding-bottom-45 {padding-bottom:4.5rem !important}
.padding-bottom-50 {padding-bottom:5rem !important}
.padding-bottom-55 {padding-bottom:5.5rem !important}
.padding-bottom-60 {padding-bottom:6rem !important}
.padding-bottom-65 {padding-bottom:6.5rem !important}
.padding-bottom-70 {padding-bottom:7rem !important}
.padding-bottom-75 {padding-bottom:7.5rem !important}
.padding-bottom-80 {padding-bottom:8rem !important}

.padding-right-0 {padding-right:0 !important}
.padding-right-5 {padding-right:.5rem !important}
.padding-right-10 {padding-right:1rem !important}
.padding-right-15 {padding-right:1.5rem !important}
.padding-right-20 {padding-right:2rem !important}
.padding-right-25 {padding-right:2.5rem !important}
.padding-right-30 {padding-right:3rem !important}
.padding-right-35 {padding-right:3.5rem !important}
.padding-right-40 {padding-right:4rem !important}
.padding-right-45 {padding-right:4.5rem !important}
.padding-right-50 {padding-right:5rem !important}
.padding-right-55 {padding-right:5.5rem !important}
.padding-right-60 {padding-right:6rem !important}
.padding-right-65 {padding-right:6.5rem !important}
.padding-right-70 {padding-right:7rem !important}
.padding-right-75 {padding-right:7.5rem !important}
.padding-right-80 {padding-right:8rem !important}



// Widths
.width10 { width:10%; }
.width15 { width:15%; }
.width20 { width:20%; }
.width25 { width:25%; }
.width30 { width:30%; }
.width35 { width:35%; }
.width40 { width:40%; }
.width45 { width:45%; }
.width50 { width:50%; }
.width55 { width:55%; }
.width60 { width:60%; }
.width65 { width:65%; }
.width70 { width:70%; }
.width75 { width:75%; }
.width80 { width:80%; }
.width85 { width:85%; }
.width90 { width:90%; }
.width95 { width:95%; }
.width100 { width:100%; }