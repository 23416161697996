.formBlock {
	padding: 0;

	.formControl {
		margin-bottom: 3rem;
		position: relative;
		z-index: 10;

		label {
			font-weight: 600;
			position: absolute;
			left: 0;
			// right: 0;
			top: 0;
			font-size: $font-size-big;
			z-index: -1;
			transition: all 0.2s;

			&.required{
				&::after{
					content: "*";
					position: absolute;
					left: 100%;
					top: -0.8rem;
					margin-left:0.5rem;
					color: $color-red;
					font-size: 3.2rem;
				}
			}
		}
		.formField:focus{
			border-color: $color-secondary;
			box-shadow: 0 1px 0 0 $color-secondary;
			& ~ label{
				font-size: $font-size-medium;
				top: -2rem;
				color: $color-secondary;

				&.required{
					&::after{
						top: -0.5rem;
						color: $color-secondary;
						font-size: 2.2rem;
					}
				}
			}
		}
		
		.input input,
		.selection, .formField {
			font-size: $font-size-medium;
			line-height: 2.6rem;
			padding: 0.5rem 0;
			border: 0;
			outline: none;
			box-shadow: none;
			background-color: transparent;
			border-bottom:solid 0.1rem $color-dark1;
			box-shadow: 0 1px 0 0 $color-white;
			width: 100%;
			@include font-md();

			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color: #282828;
			  }
			&::-moz-placeholder { /* Firefox 19+ */
				color: #282828;
			  }
			&:-ms-input-placeholder { /* IE 10+ */
				color: #282828;
			  }
			&:-moz-placeholder { /* Firefox 18- */
				color: #282828;
			  }

			.icon {
				top: 1.3rem;
			}
		}
		&.textarea{
			label{
				position: static;
				margin-bottom: 1rem;
			}
			.formField{
				padding: 1rem 1.5rem;
				border:solid 0.1rem $color-dark1;
				resize: none;
			}
		}

		&.input--filled{
			.formField{
				border-color: $color-secondary;
				box-shadow: 0 1px 0 0 $color-secondary;
				& ~ label{
					font-size: $font-size-medium;
					top: -2rem;
					color: $color-secondary;
				}
			}
		}
		.prompt.label {
			font-weight: 700;
			font-size: 1.2rem;
		}
		.errorMsg {
			display: block;
			font-size: 1.3rem;
			color: red;
			text-align: right;
			@include font-md();
			position: absolute;
			left: 0;
			right: 0;
			top: 100%;
		}

		&.static{
			label{
				position: static;
				margin-bottom: 1rem;
			}
			.formField{
				border-radius: 0.5rem;
				padding: 1rem 1.5rem;
				border:solid 0.1rem $color-dark1;
				&:focus{
					box-shadow: none;
				}
			}
			
		}
	}

	.styled-checkbox {
		position: absolute; // take it out of document flow
		opacity: 0; // hide it
		z-index: 1;
	  
		& + label {
		  position: relative;
		  cursor: pointer;
		  padding: 0;
		  font-size: $font-size-medium;
		  @include font-regular();
		  @media(min-width:$desktop-small){
			font-size: $font-size-large;
		  }
		  a{
			  @include font-bold();
		  }
		}
	  
		// Box.
		& + label:before {
		  content: '';
		  margin-right: 10px;
		  display: inline-block;
		  vertical-align: text-top;
		  width: 20px;
		  height: 20px;
		  background: $color-white;
		  border:solid 0.1rem $color-dark1;
		}
	  
		// Box hover
		&:hover + label:before {
		  background: $color-secondary;
		}
		
		// Box focus
		&:focus + label:before {
		  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
		}
	  
		// Box checked
		&:checked + label:before {
			background: $color-secondary;
		}
		
		// Disabled state label.
		&:disabled + label {
		  color: #b8b8b8;
		  cursor: auto;
		}
	  
		// Disabled box.
		&:disabled + label:before {
		  box-shadow: none;
		  background: #ddd;
		}
	  
		// Checkmark. Could be replaced with an image
		&:checked + label:after {
		  content: '';
		  position: absolute;
		  left: 0.5rem;
		  top: 1.1rem;
		  background: white;
		  width: 0.2rem;
		  height: 0.2rem;
		  box-shadow: 
			2px 0 0 white,
			4px 0 0 white,
			4px -2px 0 white,
			4px -4px 0 white,
			4px -6px 0 white,
			4px -8px 0 white;
		  transform: rotate(45deg);
		}
	}
}
