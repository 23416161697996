.notification{
    &__list{
        background-color:$color-white;
        padding: 3rem 4rem;
        border-radius: 1.5rem;
        display: flex;
        margin-bottom: 2.5rem;
        
        &--img{
            margin-right: 4rem;
            figure{
                margin-bottom: 1.5rem;
            }
            .time{
                white-space: nowrap;
            }
        }

        &--content{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .type{
                margin-right: 1.5rem;
                p{
                    margin: 0;
                    font-size: $font-size-big;
                }
            }

            .address{
                margin-right: 1.5rem;
                p{
                    margin: 0;
                    font-size: $font-size-big;
                }
            }

            .btnBlock{
                .btn{
                    white-space: nowrap;
                }
            }
        }
    }
}