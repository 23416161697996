.myRequest{
    &__list{
        background-color: $color-white;
        border-radius: 1.2rem;
        margin-bottom: 1.5rem;
        padding: 1.5rem 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        @media(min-width: $tablet-big){
            flex-direction: row;
        }

        &--img{
            width: 17rem;
            height: 16rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border:solid 0.1rem $color-light2;
            margin-bottom: 2rem;
            @media(min-width: $tablet-big){
                margin: 0;

            }
        }

        &--content{
            width: 100%;
            @media(min-width: $tablet-big){
                width: calc(100% - 33rem);
            }
            .titleBlock{
                h2{
                    margin: 0 0 1rem;
                }
            }
            .requestDetail{
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;
                @media(min-width: $tablet-big){
                    flex-direction: row;
                }
                &__item{
                    &:not(:last-child){
                        width: 100%;
                        padding: 0 1rem 0 0;
                        border-bottom: solid 0.1rem $color-light2;
                        @media(min-width: $tablet-big){
                            width: 18%;
                            border-bottom: solid 0rem $color-light2;
                            border-right: solid 0.1rem $color-light2;
                        }
                    }

                    h3{
                        margin-bottom: 1.5rem;
                    }
                    p{
                        font-size: $font-size-large;
                    }
                }
            }
        }
    }

    &.tabDetail{
        display: none;
        &.showTab{
            display: block;
        }
    }
}

.myRequestDetail{
    &__list{
        background-color: $color-white;
        border-radius: 1.2rem;
        margin-bottom: 3rem;
        padding: 1.5rem 2rem;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        &--img{
            width: 21rem;
            height: 20rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border:solid 0.1rem $color-light2;
        }

        &--content{
            width: calc(100% - 25rem);
            .titleBlock{
                margin-bottom: 5rem;
                h2{
                    margin: 0 0 1rem;
                    font-size: 3.6rem;
                }
                p{
                    font-size: $font-size-big;
                }

            }
            .requestDetail{
                display: flex;
                align-items: center;
                justify-content: space-between;
                &__item{
                    &:not(:last-child){
                        width: 18%;
                        padding: 0 1rem 0 0;
                        border-right: solid 0.1rem $color-light2;
                    }

                    h3{
                        margin-bottom: 1.5rem;
                    }
                    p{
                        font-size: $font-size-large;
                    }
                }
            }
        }
    }

    .map{
        border:solid 0.3rem $color-white;
        border-radius: 1.5rem;
        overflow: hidden;
        margin-bottom: 3rem;
    }

    .providerBlock{
        &__list{
            border-radius: 1.5rem;
            background-color: $color-white;
            overflow: hidden;
            padding: 2rem 4rem;
            margin-bottom: 2rem;
            display: flex;
            justify-content: space-between;
            
            .bidInfo{
                width: 55%;
                display: flex;
                padding: 0 2rem 0 0;
                border-right: solid 0.1rem #dfdfdf;
                &__info{
                    .titleBlock{
                        span{
                            display: block;
                            @include font-regular();
                            font-size: $font-size-large;
                        }
                    }
                }

                .review{
                    margin-bottom: 3rem;
                    &__title{
                        font-size: $font-size-large;
                        @include font-md();
                    }

                    .rating{
                        display: flex;
                        align-items: center;
                        &__star{
                            margin-right: 1.5rem;
                        }
                        .star__count{
                            font-size: $font-size-large;
                            margin: 0;
                            display: flex;
                            align-items: center;
                            span{
                                padding: 0 1.5rem;
                                font-size: 2rem;
                            }
                        }
                        .br-widget{
                            display: flex;
                            a{
                                width: 2.4rem;
                                height: 2.4rem ;
                                margin-right: 0.3rem;
                                background: image('icons.png');
                                background-repeat: no-repeat;
                                background-position: -38.5rem -0.7rem;
                                &.br-active{
                                    background-position: -35.7rem -0.7rem;
                                }
                            }
                        }
                    }
                }

                .address{
                    p{
                        font-size: $font-size-large;
                        margin: 0.5rem;
                    }
                    address{
                        font-size: 2.4rem;
                        @include font-md
                    }
                }

                .profile{
                    width: 11rem;
                    margin-right: 2rem;
                    &__avatar{
                        position: relative;
                        &--image{
                            width: 11rem;
                            height: 11rem;
                            border-radius: 100%;
                            overflow: hidden;
                        }
                        .star{
                            width: 3.9rem;
                            height: 3.9rem;
                            position: absolute;
                            top:100%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            background: image('icons.png');
                            background-repeat: no-repeat;
                            background-position: -26.8rem -6.7rem;
                        }
                    }
                }
            }
            .bidDetail{
                width: 40%;

                &__amount{
                    margin-bottom: 3rem;
                    &--label{
                        font-size: $font-size-large;
                    }
                    &--value{
                        font-size: 2.4rem;
                        @include font-md();
                    }
                }

                .btnBlock{
                    .btn{
                        min-width: 20rem;
                    }
                }
            }
        }

        .loadLink{
            display: flex;
            justify-content: center;
            margin-bottom: 3rem;
            a{
                padding: 0.2rem 5rem 0.2rem  0;
                font-size: $font-size-large;
                @include font-md();
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 2.7rem;
                    height: 2.7rem;
                    background: image('icons.png');
                    background-repeat: no-repeat;
                    background-position: -43.4rem -1.2rem;
                }
            }
        }
    }
}