.color-primary { color: $color-primary !important; }
.color-secondary { color: $color-secondary !important; }

.text-center { text-align: center; }
.text-right { text-align: right; }
.text-left { text-align: left; }

.uppercase { text-transform: uppercase; }
.nouppercase { text-transform: none !important; }

.cursor { cursor: pointer; }

.d-flex{
    display: flex !important;
}
.align-item-center{
    align-items: center !important;
}
.justify-content-center{
    justify-content: center !important;
}

.hide{
    display: none !important;
}

.show{
    display: block !important;
}