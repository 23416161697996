.modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(40, 40, 40, 0.37);
    z-index:999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;
}
.modal-active{
    .modal-overlay{
        opacity: 1;
        visibility: visible;
    }
}
.modalBlock{
    position: fixed;
    top:50%;
    left: 50%;
    width: 90%;
    max-width: 115rem;
    background-color: $color-white;
    box-shadow: 0 0.1rem 3rem 0 rgba(0,  98,  75, 0.2);  
    border-radius: 1.5rem;
    overflow: hidden;
    transform: translate(-50%, -50%);
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    margin-top: 10rem;
    transition: all 0.2s;

    &.small-modal{
        max-width: 70rem;
    }

    
    &.show-modal{
        opacity: 1;
        visibility: visible;
        margin-top: 0;
    }

    .closeModal{
        position: absolute;
        top:1rem;
        right: 1rem;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        @include icon-bg();
        background-position:-20.5rem -7.2rem;
        transition: all 0.5s;
        @media(min-width:$desktop-small){
            top:2rem;
            right: 2rem;
            width: 2.8rem;
            height: 2.8rem;
            background-position:-20.1rem -6.9rem;
        }
        &:hover{
            transform: rotate(180deg);
        }
    }

    &__content{
        display: flex;

        &--single{
            padding: 3.5rem;
            width: 100%;
        }

        &--left{
            background: #f4f4f7;
            padding: 3.5rem;
            width: 50%;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            display: none;
            @media(min-width:$desktop-small){
                display: flex;
            }

            .modalLogo{
                margin-bottom: 1rem;
            }
            .modalInfo{
                padding: 0 4rem;
                margin-bottom: 2rem;
                h3{
                    @include font-exBold();
                    margin: 1rem;
                }
                p{
                    font-size: $font-size-large;
                    line-height: 1.5;
                    margin: 0;
                }
            }
        }
        &--right{
            width: 100%;
            padding: 2rem 2rem;
            @media(min-width:$desktop-small){
                width: 50%;
                padding: 2rem 6rem;
            }
        }


        .requestRaise{
            padding: 5rem 2rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            figure{
                margin-bottom: 3rem;
                max-width: 7rem;
            }
            .titleBlock{
                flex-direction: column;
                align-items: center;
                h2{
                    text-align: center;
                }
                p{
                    @include font-semiBold();   
                }
            }
        }
    }

    .modalForm{
        .titleBlock{
            border-bottom:solid 0.1rem #dcdcdc;
            display: flex;
            justify-content: center;
            margin-bottom: 4rem;
            h2{
                margin: 0;
                padding: 1rem 1rem;
                //text-transform: uppercase;
                position: relative;
                border-bottom: solid 0.3rem transparent;
                margin-bottom: -0.2rem;
                font-size: 1.8rem;
                @include font-md();
                cursor: pointer;
                @media(min-width:$desktop-small){
                    padding: 1.2rem 1rem;
                    font-size: 1.6rem;
                }

                &.active{
                    color: $color-tertiary;
                    border-bottom: solid 0.3rem $color-tertiary;
                }

            }
        }

        .tabBlock{
            display: none;
        }

        .link{
            text-align: right;
            margin-bottom: 4rem;
            a{
                font-size: $font-size-large;
                @include font-md();
            }
        }

        .btnBlock{
            margin-bottom: 3rem;
        }
        .textBlock{
            p{
                font-size: $font-size-large;
                @include font-semiBold();
                text-align: center;
                margin-bottom: 1rem;
                a{
                    color: $color-secondary;
                    text-decoration: underline;
                }
            }
        }

        .formBlock{
            max-height: 28rem;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}