body {
	font-family: $font-family-primary;
	font-size: $font-size-default;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-family-primary;
	margin: 0 0 2rem;
	color: $color-dark3;
	font-weight: $font-weight-bold;
	line-height: $line-height-default;

	&.white { 
		color: $color-white; 
	}

	&.light { 
		font-weight: $font-weight-light; 
	}
	&.normal { 
		font-weight: $font-weight-normal; 
	}
	&.semi { 
		font-weight: $font-weight-medium; 
	}

	&.capitalize { 
		text-transform: capitalize; 
	}
}

p {
	font-family: $font-family-primary;
	font-size: $font-size-default;
	color: $color-dark3;
	margin-bottom: 2rem;
	line-height: 1.6;

	&.white { 
		color: $color-white; 
	}

	&.small { 
		font-size: $font-size-small;
	}
	&.medium { 
		font-size: $font-size-medium;
	}
	&.big { 
		font-size: $font-size-big;
	}

	&.light { 
		font-weight: $font-weight-light; 
	}
	&.semi { 
		font-weight: $font-weight-medium; 
	}
	&.bold { 
		font-weight: $font-weight-bold; 
	}
}
