.dashboard{
    .devivery-mp{
        border-radius: 1.5rem;
        border:solid 0.6rem $color-white;
        margin-bottom: 2rem;
    }    

    .delivery-info{
        border-radius: 2rem;
        overflow: hidden;
        background-color: $color-white;
        padding: 6rem 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .order-id{
            font-size: 3.6rem;
            color: $color-black;
            @include font-exBold();
            margin-bottom: 1.5rem;
        }

        .order-status{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            label{
                font-size: 2.4rem;
                margin-bottom: 1.5rem;
            }
        }
    }
}
