.main{
    padding-top: 7rem;
    @media(min-width:$tablet-big){
        padding-top: 10rem;
    }
    &.dashboard{
        background: #f7f8fc;
        min-height: 100vh;
        padding-top: 10rem;
        padding-bottom: 5rem;
        @media(min-width: $tablet-big){
            margin-left: 26rem;
            padding-left: 2rem;
            padding-right: 2rem;
            padding-top: 12rem;
        }
    }
}

.banner{
    position: relative;
    figure{
        overflow: hidden;
        img{
            @media(max-width:$tablet - 1){
                max-width: none;
                height: 100%;
                transform: translate(-50%, 0);
            }
        }
    }
    .bannerContent{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 1rem;
        width: 100%;
        max-width: 94rem;

        h1{
            color: $color-white;
            text-align: center;
            margin-bottom: 0;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }

        p{
            color: $color-white;
            text-align: center;
            margin-bottom: 4rem;
        }
    }

    .searchBlock{
        background-color: $color-white;
        border-radius: 0.8rem;
        display: flex;
        flex-direction: column;
        @media(min-width:$table-small){
            margin: 0 2.5rem;  
            flex-direction: row;  
        }
        
        &__location{
            width: 100%;
            padding: 1rem;
            border-bottom:solid 0.1rem $color-light2;
            display: flex;
            align-items: center;
            @media(min-width:$table-small){
                width: 40%;    
                padding: 2rem;
                border-bottom:solid 0 $color-light2;
                border-right:solid 0.1rem $color-light2;
            }
            i{
                width: 1.5rem;
                height: 2rem;
                margin-right: 1rem;
                background: image('icons.png');
                background-repeat: no-repeat;
                background-position: -5.8rem -0.7rem;
            }
            &--field{
                padding: 0.5rem 0;
                border:0;
                width: calc(100% - 3rem);
                font-size: $font-size-large;
                text-transform: uppercase;
                @include font-md();
                @media(min-width:$table-small){
                    width: 15rem;
                    @include font-regular();
                    font-size: $font-size-big;
                }
                &:focus{
                    outline: none;
                }
                &::placeholder {
                    color: $color-dark3;
                    opacity: 1;
                  }
            }
        }
        
        &__service{
            width: 100%;
            padding: 1rem 1.5rem;
            display: flex;
            align-items: center;
            @media(min-width:$table-small){
                width: 60%;
                padding: 2rem;
            }
            i{
                width: 3.3rem;
                height: 3.3rem;
                margin-right: 2rem;
                background: image('icons.png');
                background-repeat: no-repeat;
                background-position: -8.2rem -0.6rem;
                @media(min-width:$table-small){
                    margin-right: 3.5rem;
                }
            }
            &--field{
                padding: 0.5rem 0;
                border:0;
                width: calc(100% - 6rem);
                font-size: $font-size-big;
                @include font-regular();
                @media(min-width:$table-small){
                    width: calc(100% - 10rem);
                }
                &:focus{
                    outline: none;
                }
                &::placeholder {
                    color: #9d9d9d;
                    opacity: 1;
                  }
            }
        }
    }
}

.services{
    background-color: #f7f8fc;

    .titleBlock{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 3rem;
        @media(min-width:$tablet-big){
            flex-direction: row;
            align-items: center;
        }

        .title{
            margin-bottom: 1.5rem;
            @media(min-width:$tablet-big){
                margin: 0;
            }
            h2{
                margin: 0;
            }
            p{
                margin: 0;
                font-size: 2rem;
                @include font-regular();
            }
        }
        a{
            color: $color-secondary;
            text-decoration: underline;
            font-size: $font-size-big;
            &:hover{
                color: $color-primary;
                text-decoration: none;
            }
        }
    }

    &__list{
        margin-bottom: 5rem;
        &--item{
            width: 100%;
            //max-width: 20rem;
            margin: 0 0 3rem;
            padding:3rem 1.5rem;
            background-color: $color-white;
            box-shadow: 0px 10px 38px 0px rgba(10, 26, 147, 0.04);
            border-radius: 0.8rem;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            transition: all 0.2s;
            &:hover{
                box-shadow: 0px 10px 20px 0px rgba(10, 26, 147, 0.1);
            }
            figure{
                margin-bottom: 2.8rem;
                height: 9.5rem;
                display: flex;
                align-items: center;
            }
            p{
                margin: 0;
                font-size: 2rem;
                @include font-regular();
            }

            .requestBtn{
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                opacity: 0;
                visibility: hidden;
                background: rgb(19,184,165);
                background: linear-gradient(90deg, rgba(19,184,165,0.9) 0%, rgba(69,192,129,0.9) 100%);
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.5s;
                a{
                    border:solid 0.1rem $color-white;
                    border-radius: 0.6rem;
                    padding: 0.8rem 1rem;
                    font-size: $font-size-big;
                    color: $color-white;
                    text-align: center;
                    margin-top: 3rem;
                    @include font-semiBold();
                    box-shadow: 0px 10px 16px 0px rgba(8, 14, 50, 0.27);
                    transition: all 0.5s;
                    opacity: 0;
                    visibility: hidden;
                }
            }

            &:hover{
                .requestBtn{
                    opacity: 1;
                    visibility: visible;
                    a{
                        opacity: 1;
                        visibility: visible;
                        margin-top: 0;
                    }
                }
            }
        }
    }

    &__slider{
        .serviceSlider{
            &__content{
               padding: 10px;
               box-shadow: 0px 10px 38px 0px rgba(10, 26, 147, 0.04);
            }
        }
        .slick-list{
            padding: 0 !important;
        }
        .slick-dots{
            display: none !important;
        }
        .slick-arrow{
            transform: translate(0, -50%);
            position:absolute;
            top:50%;
            text-indent: -200px;
            overflow: hidden;
            border: 0;
            outline: none;
            background-color: $color-white;
            box-shadow: 0px 10px 24px 0px rgba(10, 26, 147, 0.09);  
            width: 8.5rem;
            height: 4rem;
            border-radius: 3rem;
            border:solid 0.2rem $color-white;
            background-image: image('icons.png');
            background-repeat: no-repeat;
            z-index: 5;
            &::before{
                content: "";
                position: absolute;
                top: -0.2rem;
                right: -0.2rem;
                bottom: -0.2rem;
                left: -0.2rem;
                border-radius: 3rem;
                background: rgb(19,184,165);
                background: linear-gradient(0deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);
                z-index: -1;
                display: none;
            }
            &:hover{
                border:solid 0.2rem $color-secondary;
            }
            

            &.slick-next{
                right: 0;
                background-position: -24.8rem 0.4rem;
                @media (min-width:$desktop-small) {
                    transform: translate(50%, 0);
                }
            }
            &.slick-prev{
                left: 0;
                background-position: -14.8rem 0.4rem;
                @media (min-width:$desktop-small) {
                    transform: translate(-50%, 0);
                }
            }
        }
    }
}

.feature{
    background:#f7f8fc image("feature.jpg") no-repeat top center;
    padding:5rem 0rem;
    @media (min-width:$table-small) {
        padding:10rem 0 20rem;
    }
    
    .titleBlock{
        max-width: 100rem;
        margin: 0 auto;
        margin-bottom: 6rem;
        h2, p{
            text-align: center;
            margin: 0;
            color: $color-white;
        }
        h2{
            @include font-bold();
            margin-bottom: 1rem;
        }
        p{
            font-size: $font-size-large;
        }
    }

    .featureList{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        &__list{
            max-width: 100%;
            position: relative;
            border-radius: 1.5rem;
            margin:0 0rem 3rem;
            @media(min-width:$mobile){
                max-width: 50%;
                margin:0 1.5rem 3rem;
            }
            @media(min-width:$table-small){
                max-width: 30%;
            }
            h3{
                position: absolute;
                left: 0;
                right: 0;
                bottom: 2rem;
                text-align: center;
                @include font-bold();
                color: $color-white;
            }
        }
    }
}

.app{
    padding: 5rem 0 0;
    .titleBlock{
        h1{
            @include font-bold();
        }
        p{
            font-size: $font-size-big;
            line-height: 1.8;
            margin-bottom: 4rem;
        }
        .appLink{
            display: flex;
            margin-bottom: 4rem;
            a{
                &:not(:last-child){
                    margin-right: 1.5rem;
                }
            }
        }
    }
    &__image{
        @media(min-width: $tablet-big){
            margin-top: -12rem;
        }
        @media(min-width: $desktop-small){
            margin-top: -20rem;
        }
    }
}

.happiness{
    background-color: #f7f8fc;  
    padding: 5rem 0 3rem;
    @media (min-width:$table-small) {
        padding: 10rem 0 3rem;
    }

    .titleBlock{
        margin-bottom: 3.5rem;
        h2{
            @include font-bold();
        }
        p{
            font-size: 2rem;
            margin: 0;
        }
    }

    .happinessList{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 6rem;
        &__list{
            width: 100%;
            padding: 6rem 1.5rem;
            margin: 0 0rem 3rem;
            background-color: $color-white;
            border-radius: 1.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 10px 38px 0px rgba(10, 26, 147, 0.04);
            @media (min-width:$mobile){
                width: 44%;
                margin: 0 1.5rem 3rem;
            }
            @media (min-width:$tablet-big){
                width: 30%;
            }
            @media (min-width:$desktop-small){
                width: 22%;
                margin: 0 1.5rem 0;
            }
              

            figure{
                width: 12.3rem;
                height: 12.3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 4rem;
            }
            p{
                font-size: 2.4rem;
                @include font-md();
                text-align: center;
            }
        }
    }

    .happinessInfo{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &__list{
            width: 100%;
            padding: 1rem 1rem;
            &:not(:last-child){
                border-bottom:solid 0.1rem $color-light2 ;
            }
            @media (min-width:$table-small) {
                width: 33%;
                &:not(:last-child){
                    border-bottom:solid 0 $color-light2 ;
                    border-right:solid 0.1rem $color-light2 ;
                }
            }
            
            h2, p{
                text-align: center;
            }
            h2{
                @include font-exBold();
                margin-bottom: 1rem;
            }
            p{
                font-size: 2rem;
            }
        }
    }
}