
.categoryBlock{
    background-color: #f7f8fc;
    padding: 4rem 0 20rem;
    @media(min-width:$tablet-big){
        padding: 0 0 20rem;
    }

    .categoryDetail{
        position: relative;
        background-color: $color-white;
        padding: 2rem 1.5rem 4rem;
        border-radius: 1.5rem;
        z-index: 1;
        @media(min-width:$tablet-big){
            margin-top: -20rem;
            padding: 5rem 4rem 12rem;
        }

        .titleBlock{
            max-width: 102rem;
            margin: 0 auto 4.5rem;

            h1{
                @include font-bold();
            }
            p{
                margin: 0;
                font-size: $font-size-large;
            }
        }

        &__list{
            display: flex;
            flex-wrap: wrap;
            .card{
                border: 0;
                margin:0 0 6rem;
                padding: 0 1.5rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                @media(min-width:$mobile){
                    width: 50%;
                }
                @media(min-width:$table-small){
                    width: 33%;
                }
                @media(min-width:$tablet-big){
                    width: 25%;
                }
                
                figure{
                    border-radius: 1.5rem;
                    margin-bottom: 1.8rem;
                    box-shadow: 0px 10px 76px 0px rgba(9, 26, 125, 0.03);
                }
                p{
                    font-size: 1.8rem;
                    @include font-regular();
                    text-align: center;
                    margin-bottom: 2.5rem;
                    @media(min-width:$tablet-big){
                        font-size: 2.2rem;
                    }
                }
            }
        }
    }
}