.serviceBanner{
    padding: 7rem 1rem;
    background: rgb(19,184,165);
    background: linear-gradient(90deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%); 
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media(min-width:$tablet-big){
        flex-direction: row;
    }
    h1{
        color: $color-white;
        @include font-semiBold();
        text-align: center;
        @media(min-width:$tablet-big){
            width: 35%;
            margin: 0;
        }
    }

    .searchBlock{
        background-color: $color-white;
        border-radius: 0.8rem;
        display: flex;
        flex-direction: column;
        width: 100%;
        @media(min-width:$tablet-big){
            margin: 0 2.5rem;
            flex-direction: row;
            width: 50%;
        }
        
        &__location{
            padding: 1rem;
            display: flex;
            align-items: center;
            border-bottom:solid 0.1rem $color-light2;
            @media(min-width:$tablet-big){
                padding: 2rem;
                width: 22rem;
                border-bottom:solid 0 $color-light2;
                border-right:solid 0.1rem $color-light2;
            }
            i{
                width: 1.5rem;
                height: 2rem;
                margin-right: 1rem;
                background: image('icons.png');
                background-repeat: no-repeat;
                background-position: -5.8rem -0.7rem;
            }
            &--field{
                padding: 0.5rem 0;
                border:0;
                font-size: $font-size-large;
                text-transform: uppercase;
                @include font-md();
                @media(min-width:$tablet-big){
                    font-size: $font-size-big;
                    width: 15rem;
                    @include font-regular();
                }
                &:focus{
                    outline: none;
                }
                &::placeholder {
                    color: $color-dark3;
                    opacity: 1;
                  }
            }
        }
        
        &__service{
            padding: 1rem;
            display: flex;
            align-items: center;
            @media(min-width:$tablet-big){
                padding: 2rem;
                width: calc(100% - 22rem);
            }
            i{
                width: 3.3rem;
                height: 3.3rem;
                margin-right: 1.5rem;
                background: image('icons.png');
                background-repeat: no-repeat;
                background-position: -8.2rem -0.6rem;
                @media(min-width:$tablet-big){
                    margin-right: 3.5rem;
                }
            }
            &--field{
                padding: 0.5rem 0;
                border:0;
                width: calc(100% - 10rem);
                font-size: $font-size-big;
                @include font-regular();
                &:focus{
                    outline: none;
                }
                &::placeholder {
                    color: #9d9d9d;
                    opacity: 1;
                  }
            }
        }
    }
}

.categoryList{
    background-color: #f7f8fc;
    padding: 5rem 0 5rem;
    @media(min-width:$table-small){
        padding: 10rem 0 20rem;
    }

    .titleBlock{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 5rem;
        @media(min-width:$table-small){
            flex-direction: row;
            align-items: flex-end;
        }

        .title{
            margin-bottom: 1.5rem;
            @media(min-width:$table-small){
                margin-bottom: 0rem;
            }
            h2{
                margin: 0;
            }
            p{
                margin: 0;
                font-size: 2rem;
                @include font-regular();
            }
        }
        .sort{
            display: flex;
            align-items: center;
            label{
                font-size: 1.6rem;
                margin-right:1.5rem;
                @media(min-width:$table-small){
                    font-size: 2rem;
                }
            }
            select{
                border: solid 0.1rem #dedede;
                border-radius: 0;
                padding: 1rem 1.5rem;
                font-size: 2rem;
                color: $color-dark3;
                width: 15rem;
                @media(min-width:$table-small){
                    width: 20rem;
                }
            }
        }
    }

    .featureList{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        &__list{
            max-width: 100%;
            position: relative;
            border-radius: 1.5rem;
            margin:0 0 3rem;
            padding: 0 1.5rem;
            overflow: hidden;
            @media(min-width:$mobile){
                max-width: 50%;
            }
            @media(min-width:$table-small){
                max-width: 33%;
            }
            @media(min-width:$tablet-big){
                max-width: 25%;
            }
            figure{
                border-radius: 1.5rem;
                overflow: hidden;
            }
            h3{
                position: absolute;
                left: 2rem;
                right: 2rem;
                bottom: 2rem;
                text-align: center;
                @include font-md();
                color: $color-white;
                @media(min-width:$tablet-big){
                    left: 6rem;
                    right: 6rem;
                    bottom: 5rem;
                }
            }   
            .tag{
                position: absolute;
                right: 1.5rem;
                top:2.6rem;
                padding: 0.9rem 2rem 0.8rem 1rem;
                color: $color-white;
                background: rgb(19,184,165);
                background: linear-gradient(90deg, rgba(19,184,165,1) 0%, rgba(23,185,162,1) 20%, rgba(69,192,129,1) 100%);
                font-size: $font-size-big;
                @include font-md();
                z-index: 9;
                &::after{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: -2.2rem;
                    width: 0;
                    height: 0;
                    border-width: 2.2rem;
                    border-style: solid;
                    border-color: rgba(19,184,165,1) transparent;
                    z-index: -1;
                }
            }
            .requestBtn{
                position: absolute;
                top: 0;
                right: 1.5rem;
                left: 1.5rem;
                bottom: 0;
                opacity: 0;
                border-radius: 1.5rem;
                visibility: hidden;
                background: rgb(19,184,165);
                background: linear-gradient(90deg, rgba(19,184,165,0.9) 0%, rgba(69,192,129,0.9) 100%);
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.5s;
                z-index: 11;
                a{
                    border:solid 0.1rem $color-white;
                    border-radius: 0.6rem;
                    padding: 0.8rem 1rem;
                    font-size: $font-size-big;
                    color: $color-white;
                    text-align: center;
                    margin-top: 3rem;
                    @include font-semiBold();
                    box-shadow: 0px 10px 16px 0px rgba(8, 14, 50, 0.27);
                    transition: all 0.5s;
                    opacity: 0;
                    visibility: hidden;
                }
            }

            &:hover{
                .requestBtn{
                    opacity: 1;
                    visibility: visible;
                    a{
                        opacity: 1;
                        visibility: visible;
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

.serviceDetail{
    .btnBlock{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10rem 0;

        .btn{
            font-size: 2.4rem;
            padding: 1.5rem 1.5rem;
            width: 19rem;
            position: relative;
            &:not(:last-child){
                margin-right: 2rem;
            }
            &::after{
                content: "";
                position: absolute;
                top:100%;
                left: 50%;
                width: 0;
                height: 0;
                border-width: 1.5rem;
                border-style: solid;
                border-color: #27bb96 transparent transparent;
                transform: translate(-50%, 0);
                opacity: 0;
                transition: all 0.2s;
            }
            &:hover, &.active{
                &::after{
                    opacity: 1;
                }
            }
        }
    }


    .startBidding{
        background: $color-white;
        border-radius: 1.5rem;
        padding: 10rem 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;

        .titleBlock{
            max-width: 110rem;
            margin-bottom: 3rem;
            h3{
                text-align: center;
            }
            p{
                text-align: center;
                font-size: $font-size-big;
            }
        }

        .formBlock{
            width: 100%;
            max-width: 50rem;
            label{
                display: block;
                font-size: 2.4rem;
                text-align: center;
                @include font-regular();
                margin-bottom: 2rem;
            }
            .formField{
                margin-bottom: 2rem;
            }
        }
    }
}