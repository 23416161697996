.footerWrapper{
    box-shadow: 0px 10px 76px 0px rgba(0, 0, 0, 0.16);
    background-color: $color-white;
    position: relative;
    z-index: 101;
}

.footerTop{
    padding: 3.5rem 0;
    .footerLogo{
        figure{
            margin-bottom: 2rem;
        }
        p{
            margin: 0;
            font-size: $font-size-large;
            line-height: 1.5;
        }
    }

    .footerLinks{
        display: flex;
        flex-wrap: wrap;
        h3{
            width: 100%;
        }
        &__list{
            &:not(:last-child){
                margin-right: 2rem;
            }
            a{
                display: block;
                padding: 0.5rem 0;
                @media(min-width:$table-small){
                    font-size: $font-size-large;
                }
            }
        }
    }

    .socialLinks{
        margin-bottom: 3rem;
        h3{
            width: 100%;
        }
        &__list{
            display: flex;
            a{
                width: 5.6rem;
                height: 5.6rem;
                border-radius: 1rem;
                position: relative;
                background: rgb(19,184,165);
                background: linear-gradient(90deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);
                &:not(:last-child){
                    margin-right: 1rem;
                }
                &::after{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-image: image('icons.png');
                    background-repeat: no-repeat;
                }
                &.linkedIn{
                    &::after{
                        background-position: 0.8rem -5.6rem;
                    }
                }
                &.facebook{
                    &::after{
                        background-position: -5.8rem -5.4rem;
                    }
                }
                &.twitter{
                    &::after{
                        background-position: -12.4rem -5.4rem;
                    }
                }
            }
        }
    }

    .address{
        address{
            font-size: $font-size-large;
            line-height: 2.5;
        }
    }
}

.footerBottom{
    background: rgb(19,184,165);
    background: linear-gradient(90deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);
    padding: 3rem 1.5rem;
    p{
        margin: 0;
        color: $color-white;
        font-size: $font-size-large;
        &.link{
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            @media(min-width:$table-small){
                flex-direction: row;
            }
            a, span{
                color: $color-white;
                font-size: $font-size-large;
            }
            span{
                padding: 0 1.5rem;
                display: none;
                @media(min-width:$table-small){
                    display: block;
                }
            }
        }
        
    }
}

.loaderBlock{
    position: fixed;
    top: 0;
    left: 0;
    right:0;
    bottom:0;
    background-color: rgba(255,255,255,0.8);
    justify-content: center;
    align-items: center;
    z-index: 1000;
    display: none;
    &.showLoader{
        display: flex;
    }
    .loader{
        width: 12rem;
        height: 12rem;
    }
}