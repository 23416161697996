.header {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 100;
	display: flex;
	padding: 1rem 0;
	background: white;
	box-shadow: $box-shadow-bottom;
	transition: all 0.2s;
	z-index: 20;

	.menuBlock {
		display: flex;
		align-items: center;
		.navList{
			position: absolute;
			right: -25rem;
			top: 100%;
			height: 100vh;
			width: 25rem;
			display: flex;
			flex-direction: column;
			padding: 2rem 0;
			background-color: rgba(19, 184, 165, 0.9);
			transition: all 0.2s;
			&::before{
				content: "";
				position: fixed;
				left: 0;
				right: 0;
				top: 7.2rem;
				bottom: 0;
				background-color: rgba(40, 40, 40, 0.2);
				z-index:-1;
				opacity: 0;
				visibility: hidden;
				transition: all 0.2s;
			}
			@media(min-width:$tablet-big){
				position: static;
				flex-direction: row;
				height: auto;
				width: auto;
				background-color: transparent;
				padding: 2rem 0;
			}
			&.show-nav{
				right: 0;
				&::before{
					opacity: 1;
					visibility: visible;
				}
			}
			a{
				color: $color-white;
				font-size: $font-size-large;
				@include font-md();
				border-bottom: solid 0.1rem $color-white;
				padding: 1rem 1.5rem;
				@media(min-width:$tablet-big){
					border-bottom: solid 0 $color-white;
					color: $color-dark3;
					@include font-regular();
					font-size: $font-size-big;
					text-transform: uppercase;
					font-size: $font-size-medium;
					padding: 0 1rem;
					&:hover{
						color: $color-secondary;
					}
				}
				@media(min-width:$desktop-small){
					padding: 0 1rem;
				}
				@media(min-width:$laptop){
					font-size: $font-size-big;
				}
				@media(min-width:$laptop){
					padding: 0 1.2rem;
				}
				
			}

			.signUpBtn{
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;
				background-color: $color-white;
				span{
					@include font-bold();
					display: block;
					color: $color-secondary;
					font-size: $font-size-large;
					padding: 1.5rem 1.5rem;
				}
				i{
					width: 1.5rem;
					height: 0.8rem;
					background: image('icons.png');
					background-repeat: no-repeat;
					background-position: -3.3rem -1.2rem;
					margin-left: 1rem;
					margin-right: 1.5rem;
				}
				@media(min-width:$tablet-big){
					display: none;
				}
				&__list{
					width: 100%;
					display: flex;
					flex-direction: column;
					background-color: rgba(19, 184, 165, 0.9);
					a{
						color: $color-white;
						font-size: $font-size-large;
						@include font-md();
						border-bottom: solid 0.1rem $color-white;
						padding: 1rem 1.5rem;
					}
				}
			}


			
		}
		.btnBlock{
			display: flex;
			align-items: center;
			
			.menuHandle{
				background: rgb(19,184,165);
				background: linear-gradient(90deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);	
				width: 4rem;
				height: 3.2rem;
				margin-left: 1.5rem;
				border-radius: 0.3rem;
				display: flex;
				align-items: center;
				justify-content: center;
				@media(min-width:$tablet-big){
					display: none;
				}
				img{
					width: 2.4rem;
				}
				&.active{
					img{
						width: 1.8rem;
					}
				}
			}
			
			.loginBtn, .signUpBtn{
				font-size: $font-size-large;
				@include font-md();
				justify-content: center;
				border-radius: 0.3rem;
				@media(min-width:$tablet-big){
					border-radius: 0.5rem;
					@include font-regular();
					text-transform: uppercase;
					font-size: $font-size-medium;
				}
				@media(min-width:$desktop-small){
					font-size: $font-size-big;
					width: 15rem;
				}
			}
			.loginBtn{
				margin:0 0 0 0;
				padding: 0.8rem 1rem;
				background: rgb(19,184,165);
				background: linear-gradient(90deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);	
				&:hover{
					color: $color-white;
					background: rgb(19,184,165);
					background: linear-gradient(-90deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);
				}
				@media(min-width:$tablet-big){
					margin:0 1rem 0 2rem;
					padding: 1rem 1rem;
				}
				@media(min-width:$desktop-small){
					padding: 1.2rem 1rem;
				}
			}
			.signUpBtn{
				cursor: pointer;
				padding: 1rem 0;
				background: $color_white;
				position: relative;
				margin: 0.2rem;
				color: $color-dark3;
				align-items: center;
				display: none;
				&::before{
					content: "";
					position: absolute;
					top: -0.2rem;
					left: -0.2rem;
					bottom: -0.2rem;
					right: -0.2rem;
					background: rgb(19,184,165);
					background: linear-gradient(-45deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);
					border-radius: 0.5rem;
					z-index: -1;
				}
				@media(min-width:$tablet-big){
					padding: 0.8rem 1rem;
				}
				@media(min-width:$desktop-small + 1){
					padding: 1rem 1rem;
					display: flex;
				}
				i{
					width: 1.5rem;
					height: 0.8rem;
					background: image('icons.png');
					background-repeat: no-repeat;
					background-position: -3.3rem -1.2rem;
					margin-left: 1rem;
				}
				&:hover{
					&::before{
						content: "";
						position: absolute;
						top: -0.2rem;
						left: -0.2rem;
						bottom: -0.2rem;
						right: -0.2rem;
						background: rgb(19,184,165);
						background: linear-gradient(135deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);
						border-radius: 0.5rem;
						z-index: -1;
					}
				}

				&__list{
					position: absolute;
					top: 120%;
					right: 0;
					opacity: 0;
					visibility: hidden;
					margin: 1rem 0 0;
					box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.48);
					border-radius: 0.8rem;
					background-color: $color-white;
					overflow: hidden;
					transition: all 0.2s;
					a{
						font-size: $font-size-large;
						@include font-regular();
						padding: 1.4rem 1.5rem;
						white-space: nowrap;
						display: block;
						text-transform: capitalize;
						text-align: left;
						&:not(:last-child){
							border-bottom: solid 0.1rem $color-light2;
						}
					}
				}
				&.show-list{
					.signUpBtn__list{
						top: 100%;
						opacity: 1;
						visibility: visible;
					}
				}
			}

			.navbar{
				&-nav{

				}
				.nav-item{
					.nav-link{
						color: $color-white;
					}

					.dropdown-menu{
						position: absolute;
						top: 120%;
						right: -1.5rem;
						left: auto;
						margin: 1rem 0 0;
						box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.48);
						border-radius: 0.8rem;
						background-color: $color-white;
						overflow: hidden;
						transition: all 0.2s;
						width: 20rem;
						a{
							font-size: $font-size-medium;
							@include font-regular();
							padding: 1rem 1.5rem;
							white-space: nowrap;
							display: block;
							text-align: left;
							&:not(:last-child){
								border-bottom: solid 0.1rem $color-light2;
							}
						}
					}
				}
			}
		}
	}

	.headerContent{
		display: flex;
		justify-content: space-between;
		.headerBlock{
			display: flex;
		}
	}
	
	&__logo {
		display: flex;
		a {
			display: block;
			padding: 0 2.2rem 0 0;
			margin-right: 2.5rem;
			border-right: solid 0.1rem $color-light2;
			text-align: center;
			width: 12rem;
			@media(min-width:$tablet-big){
				width: 17.2rem;
			}

			img {
				width: 100%;
				transition: all 0.2s;
			}
		}
	}

	&__location{
		display: none;
		align-items: center;
		@media(min-width:$tablet-big){
			display: flex;
		}
		a{
			display: flex;
			align-items: center;
			i{
				width: 1.3rem;
				height: 1.8rem;
				background: image('icons.png');
				background-repeat: no-repeat;
				background-position: -0.7rem -0.7rem;
				margin-right: 1.5rem;
			}
			span{
				font-size: 1.8rem;
				text-transform: uppercase;
				color: $color-secondary;
				text-decoration: underline;
				@include font-semiBold;
				@media(min-width:$tablet-big){
					font-size: $font-size-medium;
				}
				@media(min-width:$laptop){
					font-size: $font-size-big;
				}
			}
		}

		.locationDrop{
			padding: 0 1.5rem;
			.locationField{
				border: 0;
				font-size: $font-size-big;
				@include font-regular;
				max-width: 15rem;
				@media(min-width:$tablet-big){
					font-size: $font-size-medium;
				}
				@media(min-width:$laptop){
					font-size: $font-size-big;
				}
				@media(min-width:$macbook){
					max-width: none;
				}
			}
		}
	}

	&.sticky{
		.header__logo a img{
			width: 10rem;
		}
		.sideMenu{
			top: 7.4rem;
		}
	}


	.sideMenu{
		position: absolute;
		left: 0;
		top: 10rem;
		min-height: calc(100vh - 10rem);
		width: 26rem;
		background: rgb(19,184,165);
		background: linear-gradient(90deg, rgba(19,184,165,1) 0%, rgba(69,192,129,1) 100%);
		transition: all 0.2s;
		display: none;
		@media(min-width:$tablet-big){
			display: block;
		}

		&__profile{
			padding: 1.5rem 1.5rem 5rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			background-color: #0cb8af;

			&--img{
				width: 11.5rem;
				height: 11.5rem;
				border-radius: 100%;
				overflow: hidden;
				margin-bottom: 1.2rem;
			}

			&--name, &--email{
				font-size: $font-size-big;
				color: $color-white;
				margin-bottom: 0.5rem;
			}

			&--btn{
				margin-top:1rem;
			}
		}

		.sideNav{
			display: flex;
			flex-direction: column;
			height: calc(100vh - 38.5rem);
			overflow:auto;
			a{
				font-size: $font-size-large;
				color: $color-white;
				padding: 1rem 2.5rem;
				border-left: solid 0.3rem transparent;
				border-bottom: solid 0.1rem rgba(255, 255, 255, 0.4);
				display: flex;
				align-content: center;
				transition: all 0.2s;
				&:hover, &.active{
					border-left: solid 0.3rem $color-white;
					background-color: rgba(0, 0, 0, 0.15);
				}

				i{
					width: 3.2rem;
					height: 3rem;
					margin-right: 2rem;
					@include icon-bg();

					&.dashboard-icon{
						background-position: -32.8rem -12.5rem;
					}
					&.profile-icon{
						background-position: -0.8rem -12.2rem;
					}	
					&.notification-icon{
						background-position: -26.4rem -18.1rem;
					}	
					&.order-icon{
						background-position: -31.5rem -18.1rem;
					}
					&.requests-icon{
						background-position: -11.6rem -12.2rem;
					}	
					&.subscription-icon{
						background-position: -5.6rem -12.2rem;
					}
					&.service-icon{
						background-position: -35.1rem -18.1rem;
					}
					&.delivery-icon{
						background-position: -40.2rem -17.5rem;
					}
					&.transaction-icon{
						background-position: -28.1rem -12.8rem;
					}
					&.rating-icon{
						background-position: -36.9rem -12.8rem;
					}
					
				}

				span{
					width: calc(100% - 5rem);
					line-height: 2;
				}

			}
		}
	}
}
