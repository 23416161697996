.myProfile{
    background-color: $color-white;
    border-radius: 1rem;
    overflow: hidden;
    padding: 2rem 1rem;
    margin-bottom: 2.5rem;
    
    @media(min-width: $tablet-big){
        padding: 2rem 2.5rem;
        display: flex;
    }

    &__pic{
        background: #f5f5f5;
        padding: 3rem 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        
        @media(min-width: $tablet-big){
            max-width: 26rem;
        }
        &--img{
            // border-radius: 1rem;
            overflow: hidden;
            margin-bottom: 3rem;
            width: 13rem;
            height: 13rem;
            border-radius: 100%;
        }

        .imgBlock{
            position: relative;
            .editImg{
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translate(-50%,  -20%);
                width: 6rem;
                height: 6rem;
                border-radius: 100%;
                background-color: $color-white;
                box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.15);
                @include icon-bg();
                background-position: -41.8rem -5.5rem;
                cursor: pointer;
                .browse-profile{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    z-index: 1;
                    cursor: pointer;
                }
            }
        }

        &--name, &--email{
            font-size: 2rem;
            text-align: center;
            @include font-md();
        }
    }

    &__detail{
        padding: 2rem 1rem;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        @media(min-width: $tablet-big){
            padding: 3rem 4rem;
        }
        

        .editLink{
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            a{
                width: 4rem;
                height: 4rem;
                display: block;
                @include icon-bg();
                background-position: -34.4rem -6.4rem;
            }
        }

        &--list{
            width: 100%;
            padding: 0 0 3rem;
            @media(min-width: $tablet-big){
                width: 50%;
                padding: 0 1.5rem 3rem;
            }
            .label{
                font-size: $font-size-big;
                margin-bottom: 0.5rem;
            }
            .value{
                font-size: 2rem;
                @include font-md();
            }
        }

        .otherAddress{
            padding: 0 1.5rem;
            .titleBlock{
                margin-bottom: 2.5rem;
                h3{
                    text-transform: uppercase;
                    
                }
            }

            .address{
                display: flex;
                justify-content: space-between;
                margin-bottom: 2rem;
                &__list{
                    margin-right: 2.5rem    ;
                    p{
                        font-size: 1.6rem;
                    }
                }

                &__action{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    a{
                        width: 4rem;
                        height: 4rem;
                        @include icon-bg();
                        &.delete{
                            margin-right: 1.5rem;
                            background-position: -12.3rem -17.5rem;
                        }
                        &.edit{
                            background-position: -19.6rem -17.8rem;
                        }
                    }
                }
            }
        }
    }
    


    &.levelblock{
        flex-wrap: wrap;
    }
    .levelStatus{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 3rem;

        .level{
            width: 25%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-bottom: 0.5rem;
            margin-bottom: 10rem;
            position: relative;
            figure{
                margin-bottom: 2.5rem;
            }
            p{
                font-size: 2rem;
                @include font-bold();
            }
            i.locationIcon{
                width: 3.8rem;
                height:4.9rem;
                @include icon-bg();
                background-position: -6rem -17.3rem;
            }

            &::before, &::after{
                content: "";
                position: absolute;
                left: calc(50% - 2rem);
                top: 100%;
                width: calc(100% + 4rem);
            }

            &::before{
                height: 0.6rem;
                background: #d9d9d9;
                border-radius: 0.3rem;
            }
            &::after{
                display: none;
                height: 1.2rem;
                margin-top: -0.3rem;
                border-radius: 0.6rem;
                background: rgb(19,184,169);
                background: linear-gradient(90deg, rgba(19,184,169,1) 0%, rgba(69,192,130,1) 100%);
                z-index: 2;
            }

            &:last-child{
                &::before, &::after{
                    display: none;
                }
            }

            &.active{
                &::after{
                    display: block;
                }
                i.locationIcon{
                    background-position: -0.7rem -17.3rem;
                }
            }
            &.current{
                i.locationIcon{
                    background-position: -0.7rem -17.3rem;
                }
            }
        }
    }

    .levelMsg{
        width: 100%;
        &__list{
            display: flex;
            position: relative;
            padding: 0 0 5rem 8rem; 
            
            &--item{
                width: 50%;
            }

            .arrow{
                position: absolute;
                left: -0.3rem;
                top: 50%;
                width: 0;
                height: 0;
                border-width: 1.5rem;
                border-style: solid;
                border-color: transparent transparent rgb(19,184,169);
                z-index: 12;
            }

            &::before, &::after{
                content: "";
                position: absolute;
            }
            &::before{
                left: 0;
                top:0.5rem;
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 100%;
                background: rgb(19,184,169);
                background: linear-gradient(90deg, rgba(19,184,169,1) 0%, rgba(69,192,130,1) 100%);
            }
            &::after{
                left: 01rem;
                top:1rem;
                height: 100%;
                width: 0.5rem;
                background: rgb(19,184,169);
                background: linear-gradient(90deg, rgba(19,184,169,1) 0%, rgba(69,192,130,1) 100%);
            }

            &:last-child{
                padding-bottom: 0;
                &::after{
                    display: none;
                }
                .arrow{
                    display: none;
                }
            }
        }
    }


    &.changePass{
        flex-wrap: wrap;
        flex-direction: column;
        .dash_title{
            flex-direction: column;
        }
    }
}