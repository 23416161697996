.account{
    background-color: #f7f8fc;
    padding: 2rem 0 2rem;
    @media(min-width:$table-small){
        padding: 0 0 20rem;
    }

    .accountDetail{
        position: relative;
        background-color: $color-white;
        padding: 2rem 1rem 2rem;
        border-radius: 1.5rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem;
        z-index: 1;
        @media(min-width:$table-small){
            margin-top: -5rem;
            padding: 5rem 4rem 5rem;
            flex-direction: row;
        }
        @media(min-width:$tablet-big){
            margin-top: -10rem;
            padding: 5rem 4rem 5rem;
            flex-direction: row;
        }
        @media(min-width:$desktop-small){
            margin-top: -20rem;
        }
        

        figure{
            width: 22rem;
            height: 20rem;
            border:solid 0.1rem $color-light2;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1.5rem;
            margin:0 auto 2rem;
            @media(min-width:$table-small){
                margin:0 4rem 0 0;
            }

        }

        .titleBlock{
            @media(min-width:$table-small){
                width: calc(100% - 26rem);
            }

            p{
                @media(min-width:$table-small){
                    font-size: $font-size-large;
                }
                
            }
        }
        .accountInfo{
            display: flex;
            flex-direction: column;
            @media(min-width:$table-small){
                flex-direction: row;
            }
            @media(min-width:$table-small){
                flex-direction: row;
                flex-wrap: wrap;
            }
            &__list{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 1rem 1.5rem;
                &:not(:last-child){
                    border-bottom:solid 0.1rem $color-light2;
                }
                @media(min-width:$table-small){
                    width: 50%;
                    &:not(:last-child){
                        border-bottom:solid 0rem $color-light2;
                        border-right:solid 0.1rem $color-light2;
                        width: 50%;
                    }
                }
                @media(min-width:$tablet){
                    width: 40%;
                    &:not(:last-child){
                        border-bottom:solid 0rem $color-light2;
                        border-right:solid 0.1rem $color-light2;
                        width: 20%;
                    }
                }

                h3{
                    margin-bottom: 1.5rem;
                }

                p{
                    font-size: $font-size-large;
                    margin: 0;
                }
            }
        }
    }

    .map{
        border:solid 0.6rem $color-white;
        border-radius: 1rem;
        overflow: hidden;
        margin-bottom: 3rem;
    }

    .providerBlock{
        &__list{
            border-radius: 1.5rem;
            background-color: $color-white;
            overflow: hidden;
            padding: 2rem 1rem;
            margin-bottom: 2rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media(min-width:$table-small){
                padding: 2rem 4rem;
                flex-direction: row;
            }
            
            .bidInfo{
                margin-bottom: 2rem;
                border-bottom: solid 0.1rem #dfdfdf;
                @media(min-width:$table-small){
                    margin: 0;
                    padding: 0 2rem 0 0;
                    width: 60%;
                    display: flex;
                    border-bottom: solid 0rem #dfdfdf;
                    border-right: solid 0.1rem #dfdfdf;
                    
                }
                &__info{
                    .titleBlock{
                        span{
                            display: block;
                            @include font-regular();
                            font-size: $font-size-large;
                        }
                    }
                }

                .review{
                    margin-bottom: 3rem;
                    &__title{
                        font-size: $font-size-large;
                        @include font-md();
                    }

                    .rating{
                        display: flex;
                        flex-direction: column;
                        @media(min-width:$table-small){
                            align-items: center;
                            flex-direction: row;
                            flex-wrap: wrap;
                        }
                        &__star{
                            margin-right: 1.5rem;
                        }
                        .star__count{
                            font-size: $font-size-large;
                            margin: 0;
                            display: flex;
                            align-items: center;
                            span{
                                padding: 0 1rem;
                                font-size: 2rem;
                                @media(min-width:$tablet){
                                    padding: 0 1.5rem;
                                }
                            }
                        }
                        .br-widget{
                            display: flex;
                            a{
                                width: 2.4rem;
                                height: 2.4rem ;
                                margin-right: 0.3rem;
                                background: image('icons.png');
                                background-repeat: no-repeat;
                                background-position: -38.5rem -0.7rem;
                                &.br-active{
                                    background-position: -35.7rem -0.7rem;
                                }
                            }
                        }
                    }
                }

                .address{
                    p{
                        font-size: $font-size-large;
                        margin: 0.5rem;
                    }
                    address{
                        font-size: 1.8rem;
                        @include font-md();
                        @media(min-width:$tablet){
                            font-size: 2.4rem;
                        }
                    }
                }

                .profile{
                    width: 11rem;
                    margin: 0 auto 4rem;
                    @media(min-width:$table-small){
                        margin: 0 2rem 0 0;
                    }
                    &__avatar{
                        position: relative;
                        &--image{
                            width: 11rem;
                            height: 11rem;
                            border-radius: 100%;
                            overflow: hidden;
                        }
                        .star{
                            width: 3.9rem;
                            height: 3.9rem;
                            position: absolute;
                            top:100%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            background: image('icons.png');
                            background-repeat: no-repeat;
                            background-position: -26.8rem -6.7rem;
                        }
                    }
                }
            }
            .bidDetail{
                @media(min-width:$table-small){
                    width: 32%;
                }

                &__amount{
                    margin-bottom: 3rem;
                    &--label{
                        font-size: $font-size-large;
                    }
                    &--value{
                        font-size: 1.8rem;
                        @include font-md();
                        @media(min-width:$table-small){
                            font-size: 2.4rem;
                        }
                    }
                }

                .btnBlock{
                    display: flex;
                    flex-direction: column;
                    @media(min-width:$desktop-small){
                        flex-direction: row;
                    }
                    .btn{
                        min-width: 20rem;
                        margin: 0 0 1.5rem;
                        @media(min-width:$desktop-small){
                            margin: 0 1.5rem 0 0;
                        }
                    }
                }
            }
        }

        .loadLink{
            display: flex;
            justify-content: center;
            margin-bottom: 3rem;
            a{
                padding: 0.2rem 5rem 0.2rem  0;
                font-size: $font-size-large;
                @include font-md();
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 2.7rem;
                    height: 2.7rem;
                    background: image('icons.png');
                    background-repeat: no-repeat;
                    background-position: -43.4rem -1.2rem;
                }
            }
        }
    }
}