.orderBlock{
    &__list{
        background-color: $color-white;
        border-radius: 1rem;
        margin-bottom: 1.5rem;
        overflow: hidden;
        padding: 2rem 1rem;
        @media(min-width: $tablet-big){
            padding: 2rem 2.5rem;
        }
        &--info{
            padding: 0 0 2.5rem;
            @media(min-width: $tablet-big){
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .productDetail{
                
                @media(min-width: $tablet-big){
                    display: flex;
                    align-items: center;
                }
                &__img{
                    margin:0 0 2.5rem 0;
                    text-align: center;
                    @media(min-width: $tablet-big){
                        margin:0 1.5rem 0 0;
                    }
                }
                h3{
                    margin-bottom: 1rem;
                }
                .order-date{
                    font-size: 1.8rem;
                    margin-bottom: 1rem;
                    @media(min-width: $tablet-big){
                        font-size: 2.4rem;
                    }
                }
                .order-id{
                    font-size: $font-size-large;
                    @include font-regular();
                }
            }

            .order-status{
                .label{
                    @include font-regular();
                    margin-bottom: 1rem;
                    font-size: $font-size-large;
                }
                .value{
                    font-size: 2.4rem;
                    color: $color-secondary;
                    @include font-md();
                    margin: 0;

                    &.cancel{
                        color: $color-red;
                    }
                }
            }
            
            .order-amount{
                .label{
                    @include font-regular();
                    margin-bottom: 1rem;
                    font-size: $font-size-large;
                }
                .value{
                    font-size: 2.4rem;
                    @include font-md();
                    margin: 0;
                }
            }
            .order-action{
                .btn{
                    &:not(:last-child){
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        &--detail{
            padding: 2.5rem 0 0;
            border-top: solid 0.1rem $color-dark3;
            @media(min-width: $tablet-big){
                display: flex;
                justify-content: space-between;
            }
            .serviceProvider{
                display: flex;
                flex-direction: column;
                align-items: center;
                @media(min-width: $tablet-big){
                    flex-direction: row;
                }
                &__img{
                    width: 12rem;
                    height: 12rem;
                    border-radius: 100%;
                    overflow: hidden;
                    margin: 0 3rem 0 2rem;
                }

                &__detail{
                    padding: 2rem 0 0;
                    .label{
                        font-size: $font-size-large;
                        margin-bottom: 0.5rem;
                    }
                    .value{
                        font-size: 2.4rem;
                        @include font-md();
                    }
                }
            }
            .serviceAddress{
                padding: 2rem 0 0;
                .label{
                    font-size: $font-size-large;
                    margin-bottom: 0.5rem;
                }
                .address{
                    font-size: 1.8rem;
                    @include font-md();
                    @media(min-width: $tablet-big){
                        font-size: 2.4rem;
                    }
                }
            }
            .serviceRating{
                padding: 2rem 0 0;
                .label{
                    font-size: $font-size-large;
                    margin-bottom: 0.5rem;
                }
                .rating{
                    display: flex;
                    align-items: center;
                    margin-bottom: 1.5rem;
                    &__star{
                        margin-right: 1.5rem;
                    }
                    .star__count{
                        font-size: $font-size-large;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        span{
                            padding: 0 1.5rem;
                            font-size: 2rem;
                        }
                    }
                    .br-widget{
                        display: flex;
                        a{
                            width: 2.4rem;
                            height: 2.4rem ;
                            margin-right: 0.3rem;
                            background: image('icons.png');
                            background-repeat: no-repeat;
                            background-position: -38.5rem -0.7rem;
                            &.br-active{
                                background-position: -35.7rem -0.7rem;
                            }
                            &.br-selected{
                                background-position: -35.7rem -0.7rem;
                            }
                        }
                    }
                }
            }

        }
    }
}