.page-not-found{
    background: rgb(19,184,169);
    background: linear-gradient(90deg, rgba(19,184,169,1) 0%, rgba(69,192,130,1) 100%);
    min-height: 100vh;
    padding: 5rem;
    display: flex;
    justify-content: center;

    .pageNot-content{
        background-color: $color-white;
        padding: 5rem 0;
        max-width: 80rem;

        figure{
            margin-bottom: 3rem;
        }

        .titleBlock{
            h3{
                font-size: 2.5rem;
                text-align: center;
                color: #555555;
            }
        }

        .btnBlock{
            display: flex;
            justify-content: center;
            .btn{
                min-width: 20rem;
                border-radius: 2rem;
            }
        }
    }
}