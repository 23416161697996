// COLORS
$color-primary: #7dc132;
$color-secondary: #00aea9;
$color-tertiary: #078d5b;

$color-light1: #fdfdfd;
$color-light2: #e5e5e5;
$color-dark1: #878787;
$color-dark2: #666;
$color-dark3: #282828;
$color-green: #00a900;
$color-red: #b91919;
$color-white: #fff;
$color-black: #000;

// FONT FAMILY
$font-family-primary: 'Raleway', sans-serif;
$font-family-secondary: 'Raleway', sans-serif;

// FONT SIZES
$font-size-default: 1.4rem;
$font-size-tiny: 1.1rem;
$font-size-small: 1.2rem;
$font-size-medium: 1.4rem;
$font-size-large: 1.6rem;
$font-size-big: 1.8rem;

// FONT WEIGHT
$font-weight-normal: normal;
$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;

// VIEWPORTS
$macbook: 1440px;
$laptop: 1336px;
$desktop-small: 1280px;
$tablet-big: 1024px;
$tablet: 992px;
$table-small: 768px;
$mobile-start: 767px;
$mobile-big: 576px;
$mobile: 480px;
$mobile-small: 375px;
$mobile-extra-small: 320px;

// Customs
$box-shadow-default: 0 0 10px 2px rgba(black, 0.07);
$box-shadow-bottom: 0 5px 10px 2px rgba(black, 0.07);
$box-shadow-right: 5px 0 10px 2px rgba(black, 0.07);
$line-height-default: 1.4;