.aboutBanner{
    position: relative;
    display: flex;
    justify-content: flex-end;
    .titleBlock{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        h1{
            color: $color-white;
            margin: 0;
        }
    }
}

.about-us{
    background-color: #f7f8fc;
    padding: 3rem 0 20rem;

    .section{
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .titleBlock{
        margin-bottom: 10rem;
        p{
            font-size: 2rem;
            list-style: 1.6;
        }
    }
    
    .happinessList{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 6rem;
        &__list{
            width: 100%;
            padding: 6rem 1.5rem;
            margin: 0 0rem 3rem;
            background-color: $color-white;
            border-radius: 1.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 10px 38px 0px rgba(10, 26, 147, 0.04);
            border:solid 0.2rem $color-secondary;
            // position: relative;
            // z-index: 2;
            // &::after{
            //     content: "";
            //     position: absolute;
            //     top: -0.2rem;
            //     bottom: -0.2rem;
            //     left: -0.2rem;
            //     right: -0.2rem;
            //     border-radius: 1.5rem;
            //     background: rgb(19,184,165);
            //     background: linear-gradient(90deg, rgba(19,184,165,0.9) 0%, rgba(69,192,129,0.9) 100%);
            //     z-index: -1;
            // }
            @media (min-width:$mobile){
                width: 44%;
                margin: 0 1.5rem 3rem;
            }
            @media (min-width:$tablet-big){
                width: 30%;
            }
            @media (min-width:$desktop-small){
                width: 22%;
                margin: 0 1.5rem 0;
            }
              

            figure{
                width: 12.3rem;
                height: 12.3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 4rem;
            }
            p{
                font-size: 2.4rem;
                @include font-md();
                text-align: center;
                margin: 0;
            }
        }
    }

    .happinessInfo{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &__list{
            width: 100%;
            padding: 1rem 1rem;
            &:not(:last-child){
                border-bottom:solid 0.1rem $color-light2 ;
            }
            @media (min-width:$table-small) {
                width: 33%;
                &:not(:last-child){
                    border-bottom:solid 0 $color-light2 ;
                    border-right:solid 0.1rem $color-light2 ;
                }
            }
            
            h2, p{
                text-align: center;
            }
            h2{
                @include font-exBold();
                margin-bottom: 1rem;
            }
            p{
                font-size: 2rem;
            }
        }
    }
}

