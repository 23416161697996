.size56 { 
    font-size: 2.6rem; 
    @media(min-width:$table-small){
        font-size: 3.6rem; 
    }
    @media(min-width:$tablet-big){
        font-size: 5.6rem; 
    }
}
.size44 { 
    font-size: 4.4rem; 
    @media(min-width:$table-small){
        font-size: 3rem; 
    }
    @media(min-width:$tablet-big){
        font-size: 3.6rem; 
    }
}
.size35 { 
    font-size: 2.4rem; 
    @media(min-width:$table-small){
        font-size: 3rem; 
    }
    @media(min-width:$tablet-big){
        font-size: 3.6rem; 
    }
}
.size30 { font-size: 3rem;	 }
.size26 { font-size: 2.6rem; }
.size24 { font-size: 2.4rem; }
.size21 { font-size: 2.1rem; }
.size18 { font-size: 1.8rem; }