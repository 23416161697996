.bidderProfile{

    &__content{
        margin-top: -20rem;
        padding: 0 2.5rem;

        .bidderInfo{
            position: relative;
            z-index: 1;
            border-radius: 1.5rem;

            &__img{
                position: absolute;
                top:0;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 100%;
                overflow: hidden;
                width: 19rem;
                height: 19rem;
            }

            .titleBlock{
                max-width: 90%;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 4rem 0 0;
                h3{
                    @include font-exBold();
                    margin-bottom: 1rem;
                }
                .profile-level{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 2.5rem;
                    p{
                        margin: 0 2rem 0 0;
                    }
                    .star{
                        display: block;
                        width: 3.9rem;
                        height: 3.9rem;
                        background: image('icons.png');
                        background-repeat: no-repeat;
                        background-position: -26.8rem -6.7rem;
                    }
                }

                p{
                    text-align: center;
                    font-size: $font-size-big;
                }
            }
        }
    }
}