.mySubscription{
    background-color: $color-white;
    border-radius: 1.2rem;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 6px 40px 0px rgba(0, 0, 0, 0.03);
    @media(min-width:$desktop-small){
        padding: 5rem 4rem;
        flex-direction: row;
    }

    &__benefits{
        margin-bottom: 2rem;
        border-bottom: solid 0.1rem $color-light2;
        @media(min-width:$desktop-small){
            width: 50%;
            padding: 0 4rem 0 0;
            margin-bottom: 0rem;
            border-bottom: solid 0 $color-light2;
            border-right: solid 0.1rem $color-light2;
        }
        .titleBlock{
            h2{
                @include font-bold();
            }
            
        }

        &--list{
            position: relative;
            padding:0 0 4rem 4rem;
            .dot{
                position: absolute;
                top: 0.8rem;
                left: 0;
                width: 1.8rem;
                height: 1.8rem;
                background-color: $color-secondary;
                border-radius: 100%;
            }
            &::before, &::after{
                content: "";
                position: absolute;
                left: 0.8rem;
                height: 100%;
                width: 0.2rem;
                background-color: $color-secondary;
            }
            &::before{
                bottom: 100%;
                display: none;
            } 
            &::after{
                top: 1rem;

            }
            h2{
                margin-bottom: 1rem;
            }
            p{
                font-size: $font-size-large;
                margin: 0;
                @media(min-width:$desktop-small){
                    font-size: $font-size-big;
                }
            }

            &:last-child{
                &::after{
                    display: none;
                }
            }
        }
    }

    &__membership{
        @media(min-width:$desktop-small){
            width: 50%;
            padding: 0 0 0 4rem;
        }
        .titleBlock{
            h2{
                @include font-bold();
            }
            
        }

        &--plans{
            .membership--plan{
                display: block;
                margin-bottom: 2.5rem;
                border:solid 0.2rem $color-secondary;
                border-radius: 0.5rem;
                padding: 1rem 1rem 1rem 5rem;
                position: relative;
                cursor: pointer;
                @media(min-width:$desktop-small){
                    padding: 2rem 1rem 2rem 8rem;
                    border-radius: 1.5rem;
                }

                &.plan-2{
                    background-color: #f6e7df;
                }
                
                &.plan-3{
                    background-color: #cdeee8;
                }

                .radioBtn{
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;

                }
                .radioIcon{
                    position: absolute;
                    top: 50%;
                    left: 1rem;
                    transform: translate(0, -50%);
                    width: 3rem;
                    height: 3rem;
                    background-color: $color-white;
                    border:solid 0.2rem #bababa;
                    border-radius: 100%;
                    @media(min-width:$desktop-small){
                        left: 2.5rem;
                    }

                    &::after{
                        content: "";
                        position: absolute;
                        top:50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 1.7rem;
                        height: 1.7rem;
                        border-radius: 100%;
                        background-color: $color-white;
                    }
                }
                .radioBtn:checked + .radioIcon{
                    border-color: $color-secondary;
                    &::after{
                        background-color: $color-secondary;
                    }
                }
                .planName{
                    display: block;
                    font-size: 1.8rem;
                    @include font-md();
                    margin-bottom:0rem;
                    @media(min-width:$desktop-small){
                        font-size: 2.4rem;
                    }
                }
                .planDetail{
                    display: block;
                    font-size: $font-size-large;
                    @media(min-width:$desktop-small){
                        font-size: $font-size-big;
                    }
                }
            }
            .checkbox{
                margin-bottom: 3rem;
            }
            .styled-checkbox {
                position: absolute; // take it out of document flow
                opacity: 0; // hide it
                z-index: 1;
              
                & + label {
                  position: relative;
                  cursor: pointer;
                  padding: 0;
                  font-size: $font-size-large;
                  @include font-regular();
                  a{
                      @include font-bold();
                      color: $color-secondary;
                  }
                }
              
                // Box.
                & + label:before {
                  content: '';
                  margin-right: 10px;
                  display: inline-block;
                  vertical-align: text-top;
                  width: 20px;
                  height: 20px;
                  background: $color-white;
                  border:solid 0.1rem $color-dark1;
                }
              
                // Box hover
                &:hover + label:before {
                  background: $color-secondary;
                }
                
                // Box focus
                &:focus + label:before {
                  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
                }
              
                // Box checked
                &:checked + label:before {
                    background: $color-secondary;
                }
                
                // Disabled state label.
                &:disabled + label {
                  color: #b8b8b8;
                  cursor: auto;
                }
              
                // Disabled box.
                &:disabled + label:before {
                  box-shadow: none;
                  background: #ddd;
                }
              
                // Checkmark. Could be replaced with an image
                &:checked + label:after {
                  content: '';
                  position: absolute;
                  left: 0.5rem;
                  top: 1.1rem;
                  background: white;
                  width: 0.2rem;
                  height: 0.2rem;
                  box-shadow: 
                    2px 0 0 white,
                    4px 0 0 white,
                    4px -2px 0 white,
                    4px -4px 0 white,
                    4px -6px 0 white,
                    4px -8px 0 white;
                  transform: rotate(45deg);
                }
            }
        }
    }
}
